<template>
  <a-spin size="large" :spinning="loading">
    <div class="home">
      <div class="wrap-step">
        <a-steps :current="1">
          <a-step :title="$t('SelectSubscriptionPlan')" />
          <a-step :title="$t('BillingInformation')" />
          <a-step :title="$t('Payment')" />
        </a-steps>
      </div>
      <div class="wrap-pay">
        <!-- <div class="tit">{{ $t("TotalCost") }}</div> -->
        <div class="summary">
          <ul>
            <li>
              <div class="titel" :class="[lang]">
                {{ $t("SubscriptionPlan") }}
              </div>
              <div class="version" :class="[lang]">{{ $route.query.name }}</div>
            </li>
            <li>
              <div class="titel" :class="[lang]">{{ $t("BillingCycle") }}</div>
              <div
                class="version"
                :class="[lang]"
                :style="{ fontSize: lang != 'CN' ? '14px' : '28px' }"
              >
                {{
                  $route.query.duration_type == 2
                    ? $t("MonthlyPayment")
                    : $t("AnnualPayment")
                }}
              </div>
            </li>
            <li>
              <div class="titel">{{ $t("Botselection") }}</div>
              <div
                class="version"
                :style="{
                  fontSize:
                    lang != 'CN' || $route.query.campaign_type == 3
                      ? '14px'
                      : '28px',
                }"
                :class="{
                  smallSize: $route.query.campaign_type == 3 ? true : false,
                }"
              >
                <span :class="[lang]">
                  {{
                    $route.query.campaign_type == 3
                      ? $t("AISalesRep") + "," + $t("AIRecruiter")
                      : $route.query.campaign_type == "1"
                      ? $t("AISalesRep")
                      : $t("AIRecruiter")
                  }}
                </span>
              </div>
            </li>
            <li>
              <div class="titel">{{ $t("TotalPrice") }}</div>
              <div class="version">
                ${{
                  $route.query.price
                    ? parseFloat($route.query.price).toFixed(2)
                    : 0.0
                }}
              </div>
            </li>
          </ul>
        </div>
        <div
          class="binfo"
          style="margin-bottom: 11px"
          v-if="infoList.length > 0"
        >
          <span>{{ $t("BillingInformations") }}</span
          ><span class="add" @click="addHandler"
            >+{{ $t("AddBillingInformation") }}</span
          >
        </div>
        <div class="historyInfo" v-if="!isClick && infoList.length > 0">
          <ul>
            <li
              v-for="(item, index) in infoList"
              :key="index"
              @click="selectedHandler(item)"
            >
              <div class="box">
                <!-- <div class="cinfo">北京神华信息科技有限公司</div> -->
                <div class="country">
                  <span :class="[lang]">{{ $t("name") }}</span
                  >：{{
                    lang == "CN"
                      ? item.last_name + " " + item.first_name
                      : item.first_name + item.last_name
                  }}
                </div>
                <div class="addr">
                  <span :class="[lang]">{{ $t("Email") }}</span
                  >：<span>{{ item.email }}</span>
                </div>
                <div class="opr">
                  <span @click.stop="editHandler(item)">
                    <svg-icon iconClass="edit" class="cicon"></svg-icon>
                  </span>

                  <span @click.stop="delItem(item)">
                    <svg-icon iconClass="del" class="cicon"></svg-icon>
                  </span>
                </div>
                <div class="selected" v-if="item.is_default">
                  <svg-icon iconClass="selected" class="icon"></svg-icon>
                </div>
              </div>
              <!-- <div class="selectbtn" v-if="item.is_default">
                <span class="ant-radio ant-radio-checked"
                  ><span class="ant-radio-inner"></span
                ></span>
                <span>{{ $t("SetasDefault") }}</span>
              </div>
              <div class="selectbtn" v-if="!item.is_default">
                <span class="ant-radio"
                  ><span class="ant-radio-inner"></span
                ></span>
                <span>{{ $t("SetasDefault") }}</span>
              </div> -->
            </li>
          </ul>
          <div style="clear: both"></div>
        </div>

        <div class="title2" v-if="isClick">
          <p>
            {{
              isEdit
                ? $t("EditBillingInformation")
                : $t("AddBillingInformation")
            }}
          </p>
        </div>

        <div v-if="!isClick && infoList.length == 0" class="binfo">
          {{ $t("billInfo") }}
        </div>

        <div class="form-box">
          <a-form :form="form" class="form" formLayout="vertical">
            <template v-if="isClick || infoList.length == 0">
              <a-row>
                <a-col :span="12">
                  <a-form-item class="pr" :label="$t('LastName')">
                    <a-input
                      size="large"
                      class="input"
                      type="text"
                      autocomplete="off"
                      v-decorator="[
                        'last_name',
                        {
                          initialValue: selectedInfo.last_name,
                          rules: [
                            {
                              required: true,
                              message: $t('pleaseEnterLastName'),
                            },
                          ],
                        },
                      ]"
                      :placeholder="$t('pleaseEnterLastName')"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item class="pl" :label="$t('FirstName')">
                    <a-input
                      size="large"
                      class="input"
                      autocomplete="off"
                      v-decorator="[
                        'first_name',
                        {
                          initialValue: selectedInfo.first_name,
                          rules: [
                            {
                              required: true,
                              validator: function (rule, value, callback) {
                                if (!value || !value.trim('')) {
                                  callback($t('enterFirstName'));
                                  return;
                                }
                                callback();
                              },
                            },
                          ],
                        },
                      ]"
                      :placeholder="$t('enterFirstName')"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="12">
                  <a-form-item class="pr" :label="$t('Email')">
                    <a-input
                      size="large"
                      class="input"
                      type="text"
                      autocomplete="off"
                      v-decorator="[
                        'email',
                        {
                          initialValue: selectedInfo.email,
                          rules: [
                            {
                              required: true,
                              validator: function (rule, value, callback) {
                                let reg =
                                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                                if (
                                  !value ||
                                  !value.trim('') ||
                                  !reg.test(value)
                                ) {
                                  callback($t('validEmail'));
                                  return;
                                }
                                callback();
                              },
                            },
                          ],
                        },
                      ]"
                      :placeholder="$t('enterEmail')"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item class="pl" :label="$t('phoneNumber')">
                    <a-input
                      size="large"
                      class="input"
                      autocomplete="off"
                      v-decorator="[
                        'phone',
                        {
                          initialValue: selectedInfo.phone,
                          rules: [
                            {
                              required: false,
                              validator: function (rule, value, callback) {
                                let reg = /^[0-9]*$/;

                                if (value && !reg.test(value)) {
                                  callback($t('validPhone'));
                                  return;
                                }
                                callback();
                              },
                            },
                          ],
                        },
                      ]"
                      :placeholder="$t('phoneNumber')"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="12">
                  <a-form-item class="pr" :label="$t('Country')">
                    <a-select
                      size="large"
                      class="input"
                      v-decorator="[
                        'country_id',
                        {
                          initialValue: selectedInfo.country_id
                            ? selectedInfo.country_id
                            : null,
                          rules: [
                            { required: true, message: $t('SelectCountry') },
                          ],
                          trigger: 'change',
                        },
                      ]"
                      :placeholder="$t('SelectCountry')"
                    >
                      <a-select-option
                        v-for="(item, index) in locationList"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.show_name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item class="pl" :label="$t('CompanySize')">
                    <a-select
                      size="large"
                      class="input"
                      v-decorator="[
                        'size_id',
                        {
                          initialValue: selectedInfo.size_id
                            ? selectedInfo.size_id + ''
                            : null,
                        },
                      ]"
                      :placeholder="$t('CompanySize')"
                    >
                      <a-select-option
                        v-for="(item, index) in sizeList"
                        :key="index"
                        :value="index"
                        >{{ item }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
            </template>
            <template v-if="!isClick">
              <div
                class="discode"
                style="margin-bottom: 10px; cursor: pointer"
                @click="showCode"
              >
                <svg-icon iconClass="discount" class="icon"></svg-icon>
                {{ $t("DoyoudiscountCode") }}?
              </div>
              <template v-if="codeShow">
                <a-form-item
                  validate-status="success"
                  style="margin-bottom: 10px"
                >
                  <a-input
                    size="large"
                    class="input"
                    v-decorator="['disccount']"
                    :placeholder="$t('EnterDiscountCode')"
                  />
                </a-form-item>
                <!-- <p class="errorInfo" v-if="errorinfo">
              <a-icon
                type="exclamation-circle"
                style="color: #ff4e3a; margin-right: 7px"
              />{{ errorinfo }}
            </p> -->
                <p class="discountTips">
                  <svg-icon
                    iconClass="light"
                    style="margin-right: 7px"
                    class="icon"
                  ></svg-icon
                  >{{ $t("discountCodeOnce") }}
                </p>
              </template>
            </template>

            <div class="eidtBtn" v-if="isClick">
              <a-button
                size="large"
                type="primary"
                style="margin-right: 14px"
                @click="save"
                class="btn"
                >{{ $t("Save") }}</a-button
              >
              <a-button size="large" @click="cancel" class="btn">{{
                $t("Cancel")
              }}</a-button>
            </div>
            <div class="buttons">
              <a-button size="large" @click="back" class="btn">{{
                $t("Back")
              }}</a-button>
              <a-button
                size="large"
                @click="saveHandler"
                class="btn"
                type="primary"
                >{{ $t("NextStep") }}</a-button
              >
            </div>
          </a-form>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      history: [],
      codeShow: false,
      loading: true,
      isEdit: false,
      isNew: false,
      isClick: false,

      selectedInfo: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        country_id: null,
        size_id: null,
      },
      infoList: [],
      sizeList: [],
    };
  },
  created() {
    this.getLocationList();
    Promise.all([this.getinfoList(), this.getSizeList()]).then((res) => {
      console.log(res);
      this.loading = false;
    });
  },
  watch: {
    lang(val) {
      console.log("lange", val);
      this.getLocationList();
      this.getSizeList();
      // this.getTimeZone();
      // //   this.Pleaseenterthecampaignname = this.$t("Pleaseenterthecampaignname");
      // this.form.validateFields();
    },
  },
  i18n: require("../i18n"),
  methods: {
    editHandler(item) {
      this.selectedInfo = item;
      //item.is_default = 1;
      this.isEdit = true;
      this.isClick = true;
    },
    save() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          if (this.isEdit) {
            let { id } = this.selectedInfo;
            this.$axios(
              "/payment/update_ext_info",
              {
                payment_ext_id: id,
                ...values,
              },
              "post"
            ).then((res) => {
              if (res.code == 200) {
                this.$message.success(res.msg);
                this.getinfoList().then(() => {
                  this.loading = false;
                  this.isClick = false;
                  this.isNew = false;
                  this.isEdit = false;
                });
              }
            });
          }
          if (this.isNew) {
            this.$axios(
              "/payment/create_ext_info",
              {
                ...values,
              },
              "post"
            ).then((res) => {
              if (res.code == 200) {
                this.getinfoList().then(() => {
                  this.loading = false;
                  this.isClick = false;
                  this.isNew = false;
                  this.isEdit = false;
                });
              }
            });
          }
        }
      });
    },
    cancel() {
      this.isClick = false;
      this.isEdit = false;
      this.isNew = false;
    },
    delItem(item) {
      this.loading = true;
      this.$axios(
        "/payment/delete_ext_info",
        { payment_ext_id: item.id },
        "post"
      ).then((res) => {
        if (res.code == 200) {
          this.getinfoList().then(() => {
            this.loading = false;
          });
        }
      });
    },
    getSizeList() {
      return new Promise((resolve, reject) => {
        this.$axios("/public/company_size_config", {}, "post")
          .then((res) => {
            resolve(res.data);
            if (res.code == 200) {
              this.sizeList = res.data;
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    selectedHandler(obj) {
      this.infoList = this.infoList.map((item) => {
        if (obj.id == item.id) {
          item.is_default = 1;
        } else {
          item.is_default = 0;
        }

        return item;
      });
      if (this.isClick) {
        this.isClick = false;
        this.isNew = false;
        this.isEdit = false;
      }
      this.selectedInfo = obj;
    },
    addHandler() {
      if (this.isClick && this.isEdit) {
        this.$message.warning(this.$t("saveInfo"));
        return;
      }
      this.$nextTick(() => {
        this.isClick = true;
        this.isNew = true;
        setTimeout(() => {
          this.form.setFieldsValue({
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            country_id: null,
            size_id: null,
          });
        }, 100);
      });
    },
    getinfoList() {
      return new Promise((resolve, reject) => {
        this.$axios("/payment/ext_info_list", {}, "post")
          .then((res) => {
            resolve(res.data);
            if (res.code == 200) {
              this.infoList = res.data || [];
              let haveSelected = false;
              res.data.map((item) => {
                if (item.is_default) {
                  haveSelected = true;
                  this.selectedInfo = item;
                }
              });
              if (!haveSelected && this.infoList.length > 0) {
                this.infoList[0].is_default = 1;
                this.selectedInfo = this.infoList[0];
              }
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    createOrder(values, disccountInfo) {
      if (this.infoList.length > 0 && !this.selectedInfo.id) {
        this.$message.warning(this.$t("selectBillingInformation"));
        this.loading = false;
        return;
      }
      // this.form.validateFields((err, values) => {
      //   if (!err) {
      let { duration_type, campaign_type, id } = this.$route.query;
      let { country_id, size_id, first_name, last_name, email, phone } = values;
      let params = {};

      if (this.infoList.length == 0) {
        params = {
          country_id,
          size_id: parseInt(size_id),
          first_name,
          last_name,
          email,
          phone,
        };
      } else {
        params.payment_ext_id = this.selectedInfo.id;
      }
      // if (card) {
      //   let [credit_card_no, credit_card_rel_id] = card.split("|");
      //   params = {
      //     credit_card_no,
      //     credit_card_rel_id: parseInt(credit_card_rel_id),
      //   };
      // }
      if (disccountInfo.discount_code) {
        params.discount_code = this.discountInfo.discount_code;
      }
      console.log(this.$route.query.upgrade_free);
      this.$axios(
        "/payment/create_order",
        {
          duration_type: parseInt(duration_type),
          campaign_type: parseInt(campaign_type),
          subscription_plan_id: parseInt(id),
          upgrade_free: this.$route.query.upgrade_free
            ? parseInt(this.$route.query.upgrade_free)
            : 0,
          ...params,
        },
        "post"
      )
        .then((res) => {
          this.loading = false;
          if (res.code == 200) {
            console.log(res);
            // this.$message.success(res.msg);
            this.$router.push({
              path: "/setting/userSetting/planStep3",
              query: { ...this.$route.query, ...res.data, ...disccountInfo },
            });
            // this.$router.push({
            //   path: "/setting/bindAccount",
            //   query: {
            //     order_id: res.data.order_id,
            //     campaign_type: parseInt(campaign_type),
            //   },
            // });
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
      // }
      // });
    },
    validDiscountCode(disccount, values) {
      this.$axios(
        "/payment/discount_code_check",
        {
          discount_code: disccount.trim(),
          subscription_plan_id: parseInt(this.$route.query.id),
        },
        "post"
      )
        .then((res) => {
          if (res.code == 200) {
            this.discountInfo = res.data;
            this.createOrder(values, res.data);
            // this.$router.push({
            //   path: "/setting/userSetting/planStep3",
            //   query: { ...this.$route.query, ...res.data, ...values },
            // });
          } else {
            this.loading = false;
            this.errorinfo = res.msg;
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    showCode() {
      this.codeShow = true;
    },
    saveHandler() {
      if (this.isClick) {
        this.$message.warning(this.$t("saveInfo"));
        return;
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          let { disccount } = values;
          this.loading = true;
          if (disccount) {
            this.validDiscountCode(disccount, values);
          } else {
            this.createOrder(values, {});
            // this.$router.push({
            //   path: "/setting/userSetting/planStep3",
            //   query: { ...this.$route.query, ...values },
            // });
          }
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  margin-top: 50px;
  padding: 0px 30px 20px 30px;
}
.wrap-step {
  padding-top: 53px;
  width: 800px;
  margin: 0 auto;
  /deep/.ant-steps-item-title::after {
    background-color: #e1e1e1;
    height: 2px;
  }
  /deep/.ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #e1e1e1;
  }
  /deep/.ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #e1e1e1;
  }
}
.wrap-pay {
  width: 930px;
  margin: 0 auto;
  margin-top: 28px;
  padding: 40px;
  background: #ffffff;
  border-radius: 4px;
  .tit {
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    margin-bottom: 10px;
    text-align: center;
  }
  .money {
    font-weight: bold;
    font-size: 48px;
    color: #0e756a;
    margin-bottom: 40px;
    border-bottom: #e5e5e5 solid 1px;
    text-align: center;
  }
}
.binfo {
  font-weight: bold;
  font-size: 18px;
  color: #000000;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  .add {
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    color: #0e756a;
  }
}
.historyInfo {
  ul {
    width: 100%;
    li {
      float: left;
      margin-right: 35px;
      margin-bottom: 20px;
      position: relative;
      cursor: pointer;
      .selected {
        width: 30px;
        height: 30px;
        background: #ebebeb;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        .icon {
          width: 15px;
          height: 11px;
        }
      }
      .box {
        width: 344px;
        box-sizing: border-box;
        padding: 18px 18px 14px 18px;
        border: 14px solid #ebebeb;
        font-weight: 400;
        font-size: 13px;
        color: #000000;
        .country {
          margin-top: 12px;
          display: flex;
        }
        .addr {
          margin-top: 9px;
          display: flex;
        }
        .zcode {
          margin-top: 19px;
        }

        .opr {
          margin-top: 4px;
          text-align: right;
          .cicon {
            width: 26px;
            height: 26px;
            margin-left: 25px;
            cursor: pointer;
          }
        }
      }
      .selectbtn {
        margin-top: 17px;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        cursor: pointer;
      }
    }
  }
}
.summary {
  padding-bottom: 30px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 39px;
  ul {
    display: flex;
    justify-content: space-between;
    li {
      width: 175px;
      height: 114px;
      text-align: center;
      background: #edf4f5;
      border-radius: 9px 9px 9px 9px;
      .titel {
        font-weight: bold;
        font-size: 14px;
        color: #000000;
        height: 19px;
        line-height: 19px;
        margin-top: 20px;
      }

      .version {
        font-weight: bold;
        font-size: 28px;
        color: #0e756a;
        height: 37px;
        margin-top: 12px;
        line-height: 37px;
      }
      .smallSize {
        font-size: 14px;
      }
    }
  }
}
.pr {
  padding-right: 45px;
}
.pl {
  padding-left: 45px;
}
.buttons {
  text-align: center;
  margin-top: 30px;
  .btn {
    width: 200px;
    height: 60px;
    margin-left: 20px;
  }
}

.title2 {
  margin-top: 13px;
  border-top: 1px solid #e5e5e5;
  padding-top: 30px;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  .avt {
    width: 24px;
    border-top: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    background: #fff;
  }
}
.discode {
  font-weight: 400;
  font-size: 14px;
  color: #0e756a;
  cursor: pointer;
  .icon {
  }
}
.discountTips {
  font-weight: 400;
  font-size: 14px;
  color: #ffb175;
}
</style>
