var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-spin", { attrs: { size: "large", spinning: _vm.loading } }, [
    _c("div", { staticClass: "home" }, [
      _c(
        "div",
        { staticClass: "wrap-step" },
        [
          _c(
            "a-steps",
            { attrs: { current: 1 } },
            [
              _c("a-step", {
                attrs: { title: _vm.$t("SelectSubscriptionPlan") }
              }),
              _c("a-step", { attrs: { title: _vm.$t("BillingInformation") } }),
              _c("a-step", { attrs: { title: _vm.$t("Payment") } })
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "wrap-pay" }, [
        _c("div", { staticClass: "summary" }, [
          _c("ul", [
            _c("li", [
              _c("div", { staticClass: "titel", class: [_vm.lang] }, [
                _vm._v(" " + _vm._s(_vm.$t("SubscriptionPlan")) + " ")
              ]),
              _c("div", { staticClass: "version", class: [_vm.lang] }, [
                _vm._v(_vm._s(_vm.$route.query.name))
              ])
            ]),
            _c("li", [
              _c("div", { staticClass: "titel", class: [_vm.lang] }, [
                _vm._v(_vm._s(_vm.$t("BillingCycle")))
              ]),
              _c(
                "div",
                {
                  staticClass: "version",
                  class: [_vm.lang],
                  style: { fontSize: _vm.lang != "CN" ? "14px" : "28px" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$route.query.duration_type == 2
                          ? _vm.$t("MonthlyPayment")
                          : _vm.$t("AnnualPayment")
                      ) +
                      " "
                  )
                ]
              )
            ]),
            _c("li", [
              _c("div", { staticClass: "titel" }, [
                _vm._v(_vm._s(_vm.$t("Botselection")))
              ]),
              _c(
                "div",
                {
                  staticClass: "version",
                  class: {
                    smallSize:
                      _vm.$route.query.campaign_type == 3 ? true : false
                  },
                  style: {
                    fontSize:
                      _vm.lang != "CN" || _vm.$route.query.campaign_type == 3
                        ? "14px"
                        : "28px"
                  }
                },
                [
                  _c("span", { class: [_vm.lang] }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$route.query.campaign_type == 3
                            ? _vm.$t("AISalesRep") + "," + _vm.$t("AIRecruiter")
                            : _vm.$route.query.campaign_type == "1"
                            ? _vm.$t("AISalesRep")
                            : _vm.$t("AIRecruiter")
                        ) +
                        " "
                    )
                  ])
                ]
              )
            ]),
            _c("li", [
              _c("div", { staticClass: "titel" }, [
                _vm._v(_vm._s(_vm.$t("TotalPrice")))
              ]),
              _c("div", { staticClass: "version" }, [
                _vm._v(
                  " $" +
                    _vm._s(
                      _vm.$route.query.price
                        ? parseFloat(_vm.$route.query.price).toFixed(2)
                        : 0.0
                    ) +
                    " "
                )
              ])
            ])
          ])
        ]),
        _vm.infoList.length > 0
          ? _c(
              "div",
              {
                staticClass: "binfo",
                staticStyle: { "margin-bottom": "11px" }
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.$t("BillingInformations")))]),
                _c(
                  "span",
                  { staticClass: "add", on: { click: _vm.addHandler } },
                  [_vm._v("+" + _vm._s(_vm.$t("AddBillingInformation")))]
                )
              ]
            )
          : _vm._e(),
        !_vm.isClick && _vm.infoList.length > 0
          ? _c("div", { staticClass: "historyInfo" }, [
              _c(
                "ul",
                _vm._l(_vm.infoList, function(item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      on: {
                        click: function($event) {
                          return _vm.selectedHandler(item)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "country" }, [
                          _c("span", { class: [_vm.lang] }, [
                            _vm._v(_vm._s(_vm.$t("name")))
                          ]),
                          _vm._v(
                            "：" +
                              _vm._s(
                                _vm.lang == "CN"
                                  ? item.last_name + " " + item.first_name
                                  : item.first_name + item.last_name
                              ) +
                              " "
                          )
                        ]),
                        _c("div", { staticClass: "addr" }, [
                          _c("span", { class: [_vm.lang] }, [
                            _vm._v(_vm._s(_vm.$t("Email")))
                          ]),
                          _vm._v("："),
                          _c("span", [_vm._v(_vm._s(item.email))])
                        ]),
                        _c("div", { staticClass: "opr" }, [
                          _c(
                            "span",
                            {
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.editHandler(item)
                                }
                              }
                            },
                            [
                              _c("svg-icon", {
                                staticClass: "cicon",
                                attrs: { iconClass: "edit" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.delItem(item)
                                }
                              }
                            },
                            [
                              _c("svg-icon", {
                                staticClass: "cicon",
                                attrs: { iconClass: "del" }
                              })
                            ],
                            1
                          )
                        ]),
                        item.is_default
                          ? _c(
                              "div",
                              { staticClass: "selected" },
                              [
                                _c("svg-icon", {
                                  staticClass: "icon",
                                  attrs: { iconClass: "selected" }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticStyle: { clear: "both" } })
            ])
          : _vm._e(),
        _vm.isClick
          ? _c("div", { staticClass: "title2" }, [
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.isEdit
                        ? _vm.$t("EditBillingInformation")
                        : _vm.$t("AddBillingInformation")
                    ) +
                    " "
                )
              ])
            ])
          : _vm._e(),
        !_vm.isClick && _vm.infoList.length == 0
          ? _c("div", { staticClass: "binfo" }, [
              _vm._v(" " + _vm._s(_vm.$t("billInfo")) + " ")
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "form-box" },
          [
            _c(
              "a-form",
              {
                staticClass: "form",
                attrs: { form: _vm.form, formLayout: "vertical" }
              },
              [
                _vm.isClick || _vm.infoList.length == 0
                  ? [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "pr",
                                  attrs: { label: _vm.$t("LastName") }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "last_name",
                                          {
                                            initialValue:
                                              _vm.selectedInfo.last_name,
                                            rules: [
                                              {
                                                required: true,
                                                message: _vm.$t(
                                                  "pleaseEnterLastName"
                                                )
                                              }
                                            ]
                                          }
                                        ],
                                        expression:
                                          "[\n                      'last_name',\n                      {\n                        initialValue: selectedInfo.last_name,\n                        rules: [\n                          {\n                            required: true,\n                            message: $t('pleaseEnterLastName'),\n                          },\n                        ],\n                      },\n                    ]"
                                      }
                                    ],
                                    staticClass: "input",
                                    attrs: {
                                      size: "large",
                                      type: "text",
                                      autocomplete: "off",
                                      placeholder: _vm.$t("pleaseEnterLastName")
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "pl",
                                  attrs: { label: _vm.$t("FirstName") }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "first_name",
                                          {
                                            initialValue:
                                              _vm.selectedInfo.first_name,
                                            rules: [
                                              {
                                                required: true,
                                                validator: function(
                                                  rule,
                                                  value,
                                                  callback
                                                ) {
                                                  if (
                                                    !value ||
                                                    !value.trim("")
                                                  ) {
                                                    callback(
                                                      _vm.$t("enterFirstName")
                                                    )
                                                    return
                                                  }
                                                  callback()
                                                }
                                              }
                                            ]
                                          }
                                        ],
                                        expression:
                                          "[\n                      'first_name',\n                      {\n                        initialValue: selectedInfo.first_name,\n                        rules: [\n                          {\n                            required: true,\n                            validator: function (rule, value, callback) {\n                              if (!value || !value.trim('')) {\n                                callback($t('enterFirstName'));\n                                return;\n                              }\n                              callback();\n                            },\n                          },\n                        ],\n                      },\n                    ]"
                                      }
                                    ],
                                    staticClass: "input",
                                    attrs: {
                                      size: "large",
                                      autocomplete: "off",
                                      placeholder: _vm.$t("enterFirstName")
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "pr",
                                  attrs: { label: _vm.$t("Email") }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "email",
                                          {
                                            initialValue:
                                              _vm.selectedInfo.email,
                                            rules: [
                                              {
                                                required: true,
                                                validator: function(
                                                  rule,
                                                  value,
                                                  callback
                                                ) {
                                                  var reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                                                  if (
                                                    !value ||
                                                    !value.trim("") ||
                                                    !reg.test(value)
                                                  ) {
                                                    callback(
                                                      _vm.$t("validEmail")
                                                    )
                                                    return
                                                  }
                                                  callback()
                                                }
                                              }
                                            ]
                                          }
                                        ],
                                        expression:
                                          "[\n                      'email',\n                      {\n                        initialValue: selectedInfo.email,\n                        rules: [\n                          {\n                            required: true,\n                            validator: function (rule, value, callback) {\n                              let reg =\n                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$/;\n                              if (\n                                !value ||\n                                !value.trim('') ||\n                                !reg.test(value)\n                              ) {\n                                callback($t('validEmail'));\n                                return;\n                              }\n                              callback();\n                            },\n                          },\n                        ],\n                      },\n                    ]"
                                      }
                                    ],
                                    staticClass: "input",
                                    attrs: {
                                      size: "large",
                                      type: "text",
                                      autocomplete: "off",
                                      placeholder: _vm.$t("enterEmail")
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "pl",
                                  attrs: { label: _vm.$t("phoneNumber") }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "phone",
                                          {
                                            initialValue:
                                              _vm.selectedInfo.phone,
                                            rules: [
                                              {
                                                required: false,
                                                validator: function(
                                                  rule,
                                                  value,
                                                  callback
                                                ) {
                                                  var reg = /^[0-9]*$/

                                                  if (
                                                    value &&
                                                    !reg.test(value)
                                                  ) {
                                                    callback(
                                                      _vm.$t("validPhone")
                                                    )
                                                    return
                                                  }
                                                  callback()
                                                }
                                              }
                                            ]
                                          }
                                        ],
                                        expression:
                                          "[\n                      'phone',\n                      {\n                        initialValue: selectedInfo.phone,\n                        rules: [\n                          {\n                            required: false,\n                            validator: function (rule, value, callback) {\n                              let reg = /^[0-9]*$/;\n\n                              if (value && !reg.test(value)) {\n                                callback($t('validPhone'));\n                                return;\n                              }\n                              callback();\n                            },\n                          },\n                        ],\n                      },\n                    ]"
                                      }
                                    ],
                                    staticClass: "input",
                                    attrs: {
                                      size: "large",
                                      autocomplete: "off",
                                      placeholder: _vm.$t("phoneNumber")
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "pr",
                                  attrs: { label: _vm.$t("Country") }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [
                                            "country_id",
                                            {
                                              initialValue: _vm.selectedInfo
                                                .country_id
                                                ? _vm.selectedInfo.country_id
                                                : null,
                                              rules: [
                                                {
                                                  required: true,
                                                  message: _vm.$t(
                                                    "SelectCountry"
                                                  )
                                                }
                                              ],
                                              trigger: "change"
                                            }
                                          ],
                                          expression:
                                            "[\n                      'country_id',\n                      {\n                        initialValue: selectedInfo.country_id\n                          ? selectedInfo.country_id\n                          : null,\n                        rules: [\n                          { required: true, message: $t('SelectCountry') },\n                        ],\n                        trigger: 'change',\n                      },\n                    ]"
                                        }
                                      ],
                                      staticClass: "input",
                                      attrs: {
                                        size: "large",
                                        placeholder: _vm.$t("SelectCountry")
                                      }
                                    },
                                    _vm._l(_vm.locationList, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: index,
                                          attrs: { value: item.id }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.show_name) + " "
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "pl",
                                  attrs: { label: _vm.$t("CompanySize") }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [
                                            "size_id",
                                            {
                                              initialValue: _vm.selectedInfo
                                                .size_id
                                                ? _vm.selectedInfo.size_id + ""
                                                : null
                                            }
                                          ],
                                          expression:
                                            "[\n                      'size_id',\n                      {\n                        initialValue: selectedInfo.size_id\n                          ? selectedInfo.size_id + ''\n                          : null,\n                      },\n                    ]"
                                        }
                                      ],
                                      staticClass: "input",
                                      attrs: {
                                        size: "large",
                                        placeholder: _vm.$t("CompanySize")
                                      }
                                    },
                                    _vm._l(_vm.sizeList, function(item, index) {
                                      return _c(
                                        "a-select-option",
                                        { key: index, attrs: { value: index } },
                                        [_vm._v(_vm._s(item))]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  : _vm._e(),
                !_vm.isClick
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "discode",
                          staticStyle: {
                            "margin-bottom": "10px",
                            cursor: "pointer"
                          },
                          on: { click: _vm.showCode }
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "icon",
                            attrs: { iconClass: "discount" }
                          }),
                          _vm._v(
                            " " + _vm._s(_vm.$t("DoyoudiscountCode")) + "? "
                          )
                        ],
                        1
                      ),
                      _vm.codeShow
                        ? [
                            _c(
                              "a-form-item",
                              {
                                staticStyle: { "margin-bottom": "10px" },
                                attrs: { "validate-status": "success" }
                              },
                              [
                                _c("a-input", {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: ["disccount"],
                                      expression: "['disccount']"
                                    }
                                  ],
                                  staticClass: "input",
                                  attrs: {
                                    size: "large",
                                    placeholder: _vm.$t("EnterDiscountCode")
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "p",
                              { staticClass: "discountTips" },
                              [
                                _c("svg-icon", {
                                  staticClass: "icon",
                                  staticStyle: { "margin-right": "7px" },
                                  attrs: { iconClass: "light" }
                                }),
                                _vm._v(_vm._s(_vm.$t("discountCodeOnce")) + " ")
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm.isClick
                  ? _c(
                      "div",
                      { staticClass: "eidtBtn" },
                      [
                        _c(
                          "a-button",
                          {
                            staticClass: "btn",
                            staticStyle: { "margin-right": "14px" },
                            attrs: { size: "large", type: "primary" },
                            on: { click: _vm.save }
                          },
                          [_vm._v(_vm._s(_vm.$t("Save")))]
                        ),
                        _c(
                          "a-button",
                          {
                            staticClass: "btn",
                            attrs: { size: "large" },
                            on: { click: _vm.cancel }
                          },
                          [_vm._v(_vm._s(_vm.$t("Cancel")))]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "buttons" },
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "btn",
                        attrs: { size: "large" },
                        on: { click: _vm.back }
                      },
                      [_vm._v(_vm._s(_vm.$t("Back")))]
                    ),
                    _c(
                      "a-button",
                      {
                        staticClass: "btn",
                        attrs: { size: "large", type: "primary" },
                        on: { click: _vm.saveHandler }
                      },
                      [_vm._v(_vm._s(_vm.$t("NextStep")))]
                    )
                  ],
                  1
                )
              ],
              2
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }